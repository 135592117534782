import { MemoryCell } from '@api/models/memory-cell.entity';
import { createAction, props } from '@ngrx/store';

export interface MemoryCellUpdateAction {
	gridId: string;
	answerKind: 'hit' | 'miss';
}

export interface MemoryCellUpdateSuccessAction {
	gridId: string;
	cell: MemoryCell;
	previousCellsHitted: MemoryCell[];
}

export interface MemoryCellUpdateSilentSuccessAction {
	gridId: string;
	cell: MemoryCell;
}

export class MemoryCellActions {
	public static update = createAction(
		'[MemoryCell] Update',
		props<MemoryCellUpdateAction>(),
	);

	public static updateSuccess = createAction(
		'[MemoryCell] Update Success',
		props<MemoryCellUpdateSuccessAction>(),
	);

	public static updateSilentSuccess = createAction(
		'[MemoryCell] Update Silent Success',
		props<MemoryCellUpdateSilentSuccessAction>(),
	);
}
