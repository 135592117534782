/* eslint-disable @typescript-eslint/member-ordering */
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { PlayerRole, PlayersAmount, Team } from '@api/models/enums';
import { Player } from '@api/models/player.entity';
import { User } from '@api/models/user.entity';
import { PlayersUpdatedEvent } from '@core/models/games/events/players.events';
import { PlayersSockets } from '@core/services/sockets/players.sockets';
import { Store } from '@ngrx/store';

import { UsersFacade } from '../../users/users-facade';
import { PlayersActions } from './players.actions';
import { PlayersSelectors } from './players.selectors';

@Injectable()
export class PlayersFacade {
	// Selectors
	public player1$: Observable<Player> = this.store.select(
		PlayersSelectors.player1,
	);

	public player2$: Observable<Player> = this.store.select(
		PlayersSelectors.player2,
	);

	public presenter$: Observable<Player> = this.store.select(
		PlayersSelectors.presenter,
	);

	public allReady$: Observable<boolean> = this.store.select(
		PlayersSelectors.allReady,
	);

	public users$: Observable<Partial<User>[]> = this.store.select(
		PlayersSelectors.usersInPlayers,
	);

	public all$: Observable<Array<Player>> = this.store.select(
		PlayersSelectors.all,
	);

	public allSortedForPick$: Observable<Player[]> = this.store
		.select(PlayersSelectors.all)
		.pipe(
			// eslint-disable-next-line @ngrx/avoid-mapping-selectors
			map((players: Player[]) => {
				const blueTeam = players.filter(
					(player: Player) => player.team === Team.Blue,
				);
				const presenter = players.find(
					(player: Player) => player.role === PlayerRole.Presenter,
				);
				const orangeTeam = players.filter(
					(player: Player) => player.team === Team.Orange,
				);

				const playersSorted: Player[] = [];

				const [p1Blue, ...blueGuests] = blueTeam;
				const [p1Orange, ...orangeGuests] = orangeTeam;

				playersSorted.push(p1Blue);
				if (presenter) playersSorted.push(presenter);
				playersSorted.push(p1Orange);

				if (blueGuests.length) playersSorted.push(...blueGuests);
				if (orangeGuests.length) playersSorted.push(...orangeGuests);

				return playersSorted;
			}),
		);

	public current$: Observable<Player> = this.usersFacade.current$.pipe(
		filter((user: User) => !!user),
		withLatestFrom(this.all$),
		map(([current, players]: [User, Player[]]) => {
			return players.find(
				({ user }: Player) =>
					user && (user as User)._id === current._id,
			);
		}),
	);

	public currentUserIsPresenter$: Observable<boolean> = this.presenter$.pipe(
		withLatestFrom(this.usersFacade.currentId$),
		map(([presenter, userId]: [Player, string]) => {
			if (!presenter || !presenter.user) return false;

			return (presenter.user as User)._id === userId;
		}),
	);

	public currentUserIsFirstPlayerPresenter$: Observable<boolean> =
		this.all$.pipe(
			withLatestFrom(this.usersFacade.currentId$),
			map(([allPlayers, userId]: [Player[], string]) => {
				if (allPlayers.length > PlayersAmount.Two) return false;

				const [firstPlayer] = allPlayers;
				if (!firstPlayer || !firstPlayer.user) return false;

				return (firstPlayer.user as User)._id === userId;
			}),
		);

	public playersFrom$ = (team: Team): Observable<Player[]> =>
		this.store.select(PlayersSelectors.playersFrom(team));

	constructor(
		private store: Store,
		private usersFacade: UsersFacade,
		private playersSockets: PlayersSockets,
	) {
		this.playersSockets.updated$.subscribe(
			({ player }: PlayersUpdatedEvent) =>
				this.store.dispatch(PlayersActions.updateSuccess({ player })),
		);
	}

	public toggleReady(ready: boolean): void {
		this.store.dispatch(PlayersActions.toggleReady({ ready }));
	}
}
