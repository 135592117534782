import { GameType, PhaseKind, PlayersAmount } from '@api/models/enums';
import { Game } from '@api/models/game.entity';
import { GamesV2Query } from '@api/models/games-v2.query';
import { Phase } from '@bussiness/custom-models/game.custom-models';
import { createAction, props } from '@ngrx/store';

export interface GamesCreateAction {
	gameType: GameType;
	playersAmount: PlayersAmount;
	name: string;
	password?: string;
}

export interface GamesSetAction {
	game: Game;
}

export interface GamesGetCurrentAction {
	_id: string;
	goToRolePicker?: boolean;
}

export interface GamesLoadAction {
	query: GamesV2Query;
}

export interface GamesLoadSuccessAction {
	games: Game[];
}

export interface GamesJoinAction {
	gameId: string;
}

export interface GameUpdateAction {
	viewers?: number;
	over?: boolean;
}

export interface GameAddPhaseAction {
	kind: PhaseKind;
}

export interface GameAddPhaseSuccessAction {
	phase: Phase;
}

export class GamesActions {
	public static create = createAction(
		'[Games] Create',
		props<GamesCreateAction>(),
	);

	public static createSuccess = createAction(
		'[Games] Create Success',
		props<GamesSetAction>(),
	);

	public static getCurrent = createAction(
		'[Games] Get Current',
		props<GamesGetCurrentAction>(),
	);

	public static getCurrentSuccess = createAction(
		'[Games] Get Current Success',
		props<GamesSetAction>(),
	);

	public static load = createAction('[Games] Load', props<GamesLoadAction>());

	public static loadSuccess = createAction(
		'[Games] Load Success',
		props<GamesLoadSuccessAction>(),
	);

	public static loadNextSuccess = createAction(
		'[Games] Load Next Success',
		props<GamesLoadSuccessAction>(),
	);

	public static join = createAction('[Games] Join', props<GamesJoinAction>());

	public static finishSuccess = createAction('[Games] Finish Success');

	public static updateSuccess = createAction(
		'[Games] Update Success',
		props<GameUpdateAction>(),
	);

	public static addPhase = createAction(
		'[Games] Add Phase',
		props<GameAddPhaseAction>(),
	);

	public static addPhaseSuccess = createAction(
		'[Games] Add Phase Success',
		props<GameAddPhaseSuccessAction>(),
	);

	public static moveToNextPhase = createAction('[Games] Move To Next Phase');
}
