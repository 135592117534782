/* eslint-disable @typescript-eslint/member-ordering */
import { Observable } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GameType, PhaseKind, PlayerRole, Team } from '@api/models/enums';
import { Game } from '@api/models/game.entity';
import { GamesV2Query } from '@api/models/games-v2.query';
import { Player } from '@api/models/player.entity';
import {
	PlcGamePathsPhaseParams,
	PlcGamePhasePage,
	PlcGameRoute,
} from '@bussiness/resources/common/routes.constants';
import {
	GamesActions,
	GamesCreateAction,
	GamesLoadSuccessAction,
	GamesSetAction,
} from '@bussiness/store/features/games/games.actions';
import { GamesSelectors } from '@bussiness/store/features/games/games.selectors';
import { StringUtils } from '@bussiness/utils/string.utils';
import {
	GameNavigatedEvent,
	GameNavigationType,
	GamesPhaseAddedEvent,
	GamesUpdatedEvent,
} from '@core/models/games/events/games.events';
import { GamesSockets } from '@core/services/sockets/games.sockets';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { PlayersFacade } from './players/players.facade';

export const DEFAULT_GAME_LOAD_QUERY: GamesV2Query = {
	includeFull: true,
	gameType: GameType.All,
	over: false,
	page: 1,
};

export class BagelResults {
	public hits: number;
	public misses: number;
	public passes: number;
	public name: string;
}

export const PLAYER_PAGE_ROLES = [
	PlayerRole.Player1,
	PlayerRole.Player2,
	PlayerRole.Guest,
];

export const DASHBOARD_PAGE_ROLES = [
	PlayerRole.Presenter,
	PlayerRole.PlayerPresenter,
];

@Injectable()
export class GamesFacade {
	// Actions
	public getCurrentSuccessAction$: Observable<GamesSetAction> =
		this.actions$.pipe(ofType(GamesActions.getCurrentSuccess));

	public createdSuccessAction$: Observable<GamesSetAction> =
		this.actions$.pipe(ofType(GamesActions.createSuccess));

	public loadSuccessAction$: Observable<GamesLoadSuccessAction> =
		this.actions$.pipe(ofType(GamesActions.loadSuccess));

	// Selectors
	public timeAccumulatedUntil$ = (
		team: Team,
		phase?: PhaseKind,
	): Observable<number> =>
		this.store.select(GamesSelectors.timeAccumulatedUntil(team, phase));

	public list$: Observable<Game[]> = this.store.select(GamesSelectors.list);

	public id$: Observable<string> = this.store.select(GamesSelectors.id);

	public currentName$: Observable<string> = this.store.select(
		GamesSelectors.currentName,
	);

	public ownerName$: Observable<string> = this.store.select(
		GamesSelectors.ownerName,
	);

	public viewers$: Observable<number> = this.store.select(
		GamesSelectors.viewers,
	);

	public winner$: Observable<Player> = this.store.select(
		GamesSelectors.winner,
	);

	public results$: Observable<BagelResults[]> = this.store.select(
		GamesSelectors.results,
	);

	public over$: Observable<boolean> = this.store.select(GamesSelectors.over);

	constructor(
		private store: Store,
		private actions$: Actions,
		private gamesSockets: GamesSockets,
		private playersFacade: PlayersFacade,
		private router: Router,
	) {
		this.gamesSockets.updated$.subscribe(({ game }: GamesUpdatedEvent) =>
			this.store.dispatch(GamesActions.updateSuccess(game)),
		);

		this.gamesSockets.phaseAdded$.subscribe(
			({ phase }: GamesPhaseAddedEvent) =>
				this.store.dispatch(GamesActions.addPhaseSuccess({ phase })),
		);

		this.gamesSockets.navigated$
			.pipe(withLatestFrom(this.playersFacade.current$))
			.subscribe(
				([{ gameId, type }, currentPlayer]: [
					GameNavigatedEvent,
					Player,
				]) => {
					if (type === GameNavigationType.GameResult) {
						const params: PlcGamePathsPhaseParams = {
							gameId,
							kind: PhaseKind.Bagels,
							page: 'result',
						};
						const path =
							StringUtils.populatePath<PlcGamePathsPhaseParams>(
								PlcGameRoute.Phase,
								params,
							);
						this.router.navigateByUrl(path);
						return;
					}

					const [phase, target] = type.split(':');
					let page: PlcGamePhasePage;

					const role = currentPlayer ? currentPlayer.role : null;
					if (target === 'preview') page = 'preview';
					else if (target === 'result') page = 'result';
					else {
						if (PLAYER_PAGE_ROLES.includes(role)) {
							if (
								currentPlayer.role === PlayerRole.Guest &&
								phase === 'bagels'
							)
								page = 'viewer';
							else page = 'player';
						} else if (DASHBOARD_PAGE_ROLES.includes(role))
							page = 'dashboard';
						else page = 'viewer';
					}

					const params: PlcGamePathsPhaseParams = {
						gameId,
						kind: phase as PhaseKind,
						page,
					};
					const path =
						StringUtils.populatePath<PlcGamePathsPhaseParams>(
							PlcGameRoute.Phase,
							params,
						);
					this.router.navigateByUrl(path);
				},
			);
	}

	public create(action: GamesCreateAction): void {
		this.store.dispatch(GamesActions.create(action));
	}

	public getCurrent(gameId: string): void {
		this.store.dispatch(GamesActions.getCurrent({ _id: gameId }));
	}

	public load(query: GamesV2Query = DEFAULT_GAME_LOAD_QUERY): void {
		this.store.dispatch(GamesActions.load({ query }));
	}

	public join(gameId: string): void {
		this.store.dispatch(GamesActions.join({ gameId }));
	}

	public addPhase(kind: PhaseKind): void {
		this.store.dispatch(GamesActions.addPhase({ kind }));
	}

	public moveToNextPhase(): void {
		this.store.dispatch(GamesActions.moveToNextPhase());
	}

	public triggerNavigation(gameId: string, type: GameNavigationType): void {
		this.gamesSockets.emitNavigation({ gameId, type });
	}
}
