import { Draft, produce } from 'immer';

import { PhaseKind } from '@api/models/enums';
import { MemoryPhase } from '@api/models/memory-phase.entity';
import { on } from '@ngrx/store';

import { GamesState } from '../../games.reducer';
import {
	MemoryCellActions,
	MemoryCellUpdateSilentSuccessAction,
	MemoryCellUpdateSuccessAction,
} from './memory-cell.actions';

export const memoryCellFeatureKey = 'memoryCell';

type MemoryCellUpdateSuccessFnAction =
	| MemoryCellUpdateSuccessAction
	| MemoryCellUpdateSilentSuccessAction;

function updateSucessFunc(
	state: GamesState,
	action: MemoryCellUpdateSuccessFnAction,
): GamesState {
	const {
		gridId,
		cell: { _id: cellId, hitted },
	} = action;

	return produce(state, (newState: Draft<GamesState>) => {
		const memoryPhase = newState.current.phases.find(
			({ kind }) => kind === PhaseKind.Memory,
		) as MemoryPhase;

		const grid = memoryPhase.grids.find(({ _id }) => _id === gridId);

		const cell = grid.cells.find(({ _id }) => _id === cellId);
		cell.hitted = hitted;
	});
}

export const memoryCellReducers = [
	on(MemoryCellActions.updateSuccess, updateSucessFunc),
	on(MemoryCellActions.updateSilentSuccess, updateSucessFunc),
];
