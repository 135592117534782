import produce, { Draft } from 'immer';

import { PhaseKind } from '@api/models/enums';
import { SongPhase } from '@api/models/song-phase.entity';
import { on } from '@ngrx/store';

import { GamesState } from '../games.reducer';
import { SongPhaseActions } from './song-phase.actions';

export const songPhaseFeatureKey = 'songPhase';

function finishFn(state: GamesState): GamesState {
	return produce(state, (newState: Draft<GamesState>) => {
		const songPhase = newState.current.phases.find(
			({ kind }) => kind === PhaseKind.Song,
		) as SongPhase;

		songPhase.over = true;
	});
}

export const songPhaseReducers = [on(SongPhaseActions.finish, finishFn)];
