/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { AlphabetSoupPhase } from '@api/models/alphabet-soup-phase.entity';
import { AlphabetSoupRound } from '@api/models/alphabet-soup-round.entity';
import { BagelPhase } from '@api/models/bagel-phase.entity';
import { Bagel } from '@api/models/bagel.entity';
import {
	BagelQuestionStatus,
	PhaseKind,
	PlayerRole,
	SongClueStatus,
	Team,
} from '@api/models/enums';
import { MemoryGrid } from '@api/models/memory-grid.entity';
import { MemoryPhase } from '@api/models/memory-phase.entity';
import { Player } from '@api/models/player.entity';
import { SongClue } from '@api/models/song-clue.entity';
import { SongPhase } from '@api/models/song-phase.entity';
import { SongRound } from '@api/models/song-round.entity';
import { User } from '@api/models/user.entity';
import { Phase } from '@bussiness/custom-models/game.custom-models';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { gamesFeatureKey, GamesState } from './games.reducer';

export const selectGames = createFeatureSelector<GamesState>(gamesFeatureKey);

const RESULT_PLAYER_ROLES = [
	PlayerRole.Player1,
	PlayerRole.Player2,
	PlayerRole.PlayerPresenter,
];

export class GamesSelectors {
	public static list = createSelector(
		selectGames,
		(gamesState: GamesState) => gamesState.list,
	);

	public static id = createSelector(selectGames, (gamesState: GamesState) =>
		gamesState.current ? gamesState.current._id : null,
	);

	public static currentName = createSelector(
		selectGames,
		(gamesState: GamesState) =>
			gamesState.current ? gamesState.current.name : null,
	);

	public static ownerId = createSelector(
		selectGames,
		(gamesState: GamesState) => {
			if (!gamesState.current) return null;
			else if (typeof gamesState.current.owner === 'string')
				return gamesState.current.owner as string;
			else return (gamesState.current.owner as User)._id;
		},
	);

	public static ownerName = createSelector(
		selectGames,
		(gamesState: GamesState) => {
			if (!gamesState.current) return null;

			const { owner } = gamesState.current;
			if (owner && (owner as User).name) return (owner as User).name;
			else return null;
		},
	);

	public static viewers = createSelector(
		selectGames,
		(gamesState: GamesState) => {
			if (!gamesState.current) return null;

			return gamesState.current.viewers;
		},
	);

	public static phases = createSelector(
		selectGames,
		(gamesState: GamesState) => {
			if (!gamesState.current) return null;

			return gamesState.current.phases;
		},
	);

	public static timeAccumulatedUntil = (
		teamToCheck: Team,
		phase?: PhaseKind,
	) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			if (!gamesState.current) return null;

			const { phases, players } = gamesState.current;
			const phaseLimitIndex = !phase
				? phases.length
				: phases.findIndex(({ kind }: Phase) => kind === phase);
			const phasesToCheck = phases.slice(0, phaseLimitIndex);

			return phasesToCheck.reduce((acc, phase) => {
				if (phase.kind === PhaseKind.Memory) {
					const score = computeMemoryPhaseScore(
						phase as MemoryPhase,
						teamToCheck,
					);
					return acc + score;
				} else if (phase.kind === PhaseKind.Song) {
					const score = computeSongPhaseScore(
						phase,
						players,
						teamToCheck,
					);
					return acc + score;
				} else if (phase.kind === PhaseKind.Soups) {
					const score = computeSoupsPhaseScore(
						phase as AlphabetSoupPhase,
						teamToCheck,
					);
					return acc + score;
				}
			}, 0);
		});

	public static winner = createSelector(
		selectGames,
		({ current }: GamesState) => {
			if (!current) return null;

			const { phases, players } = current;
			const bagelPhase = phases.find(
				({ kind }: Phase) => kind === PhaseKind.Bagels,
			) as BagelPhase;
			const [resultsP1, resultsP2] = computeResults(bagelPhase.bagels);

			const { hits: hitsP1, misses: missesP1 } = resultsP1;
			const { hits: hitsP2, misses: missesP2 } = resultsP2;

			const [p1, p2] = players.filter(({ role }) =>
				RESULT_PLAYER_ROLES.includes(role),
			);
			if (hitsP1 > hitsP2 || (hitsP1 === hitsP2 && missesP1 < missesP2))
				return p1;
			else if (
				hitsP2 > hitsP1 ||
				(hitsP2 === hitsP1 && missesP2 < missesP1)
			)
				return p2;
			else return null;
		},
	);

	public static results = createSelector(
		selectGames,
		({ current }: GamesState) => {
			if (!current) return null;

			const { phases, players } = current;
			const bagelPhase = phases.find(
				({ kind }: Phase) => kind === PhaseKind.Bagels,
			) as BagelPhase;
			const results = computeResults(bagelPhase.bagels);

			const p1AndP2 = players.filter(({ role }) =>
				RESULT_PLAYER_ROLES.includes(role),
			);
			return results.map(({ hits, misses, passes }, index) => {
				const { user } = p1AndP2[index];
				const { name } = user as User;
				return {
					hits,
					misses,
					passes,
					name,
				};
			});
		},
	);

	public static over = createSelector(
		selectGames,
		(gamesState: GamesState) =>
			gamesState.current ? gamesState.current.over : null,
	);
}

const computeResults = (
	bagels: Bagel[],
): { hits: number; misses: number; passes: number }[] => {
	return bagels.map(({ questions }: Bagel) => ({
		hits: questions.filter(
			({ status }) => status === BagelQuestionStatus.Hit,
		).length,
		misses: questions.filter(
			({ status }) => status === BagelQuestionStatus.Miss,
		).length,
		passes: questions.filter(
			({ status }) =>
				status === BagelQuestionStatus.None ||
				status === BagelQuestionStatus.Pass,
		).length,
	}));
};

const computeMemoryPhaseScore = (
	phase: MemoryPhase,
	teamToCheck: Team,
): number => {
	const { grids } = phase;
	return grids
		.filter(({ team }: MemoryGrid) => team === teamToCheck)
		.map(({ score }: MemoryGrid) => score)
		.pop();
};

const computeSoupsPhaseScore = (
	phase: AlphabetSoupPhase,
	teamToCheck: Team,
): number => {
	const { rounds } = phase;
	return rounds
		.filter(({ team }: AlphabetSoupRound) => team === teamToCheck)
		.map(({ score }: AlphabetSoupRound) => score)
		.pop();
};

const computeSongPhaseScore = (
	phase: Phase,
	players: Player[],
	teamToCheck: Team,
): number => {
	const { rounds } = phase as SongPhase;
	const teamPlayerIds = players
		.filter(({ team }: Player) => team === teamToCheck)
		.map(({ _id }: Player) => _id);

	const roundsHittedByTeam = rounds.filter(({ hittedBy }: SongRound) =>
		teamPlayerIds.includes(hittedBy as string),
	);

	return roundsHittedByTeam
		.map(({ clues }: SongRound) =>
			clues
				.filter(
					({ status }: SongClue) =>
						status === SongClueStatus.Answered,
				)
				.map(({ score }: SongClue) => score)
				.pop(),
		)
		.reduce((acc, curr) => acc + curr, 0);
};
