import produce, { Draft } from 'immer';

import { PhaseKind } from '@api/models/enums';
import { MemoryPhase } from '@api/models/memory-phase.entity';
import { on } from '@ngrx/store';

import { GamesState } from '../games.reducer';
import { MemoryPhaseActions } from './memory-phase.actions';

export const memoryPhaseFeatureKey = 'memoryPhase';

function finishFn(state: GamesState): GamesState {
	return produce(state, (newState: Draft<GamesState>) => {
		const memoryPhase = newState.current.phases.find(
			({ kind }) => kind === PhaseKind.Memory,
		) as MemoryPhase;

		memoryPhase.over = true;
	});
}

export const memoryPhaseReducers = [
	on(MemoryPhaseActions.finishSuccess, finishFn),
];
