import { PlayerRole, Team } from '@api/models/enums';
import { Player } from '@api/models/player.entity';
import { User } from '@api/models/user.entity';
import { createSelector } from '@ngrx/store';

import { GamesState } from '../games.reducer';
import { selectGames } from '../games.selectors';

export class PlayersSelectors {
	public static player1 = createSelector(
		selectGames,
		(gamesState: GamesState) => {
			if (!gamesState.current) return null;

			const { players } = gamesState.current;
			if (players.length === 2) {
				const [firstPlayer] = players;
				return firstPlayer;
			} else {
				return gamesState.current.players.find(
					(p) => p.role === PlayerRole.Player1,
				);
			}
		},
	);

	public static player2 = createSelector(
		selectGames,
		(gamesState: GamesState) => {
			if (!gamesState.current) return null;

			const { players } = gamesState.current;
			if (players.length === 2) {
				const [, secondPlayer] = players;
				return secondPlayer;
			} else {
				return gamesState.current.players.find(
					(p) => p.role === PlayerRole.Player2,
				);
			}
		},
	);

	public static presenter = createSelector(
		selectGames,
		(gamesState: GamesState) => {
			if (!gamesState.current) return null;

			const { players } = gamesState.current;
			if (players.length === 2) {
				return null;
			} else {
				return gamesState.current.players.find(
					(p) => p.role === PlayerRole.Presenter,
				);
			}
		},
	);

	public static allReady = createSelector(
		selectGames,
		(gamesState: GamesState) => {
			if (!gamesState.current) return false;

			const { players } = gamesState.current;
			return !players.some((p) => !p.ready);
		},
	);

	public static all = createSelector(
		selectGames,
		(gamesState: GamesState) => {
			if (!gamesState.current) return [];

			const { players } = gamesState.current;
			return players;
		},
	);

	public static usersInPlayers = createSelector(
		selectGames,
		(gamesState: GamesState) => {
			if (!gamesState.current) return [];

			const { players } = gamesState.current;
			return players.map(({ user }: Player) => user as Partial<User>);
		},
	);

	/* eslint-disable @typescript-eslint/explicit-function-return-type */
	public static playersFrom = (team: Team) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			if (!gamesState.current) return [];

			const { players } = gamesState.current;
			return players.filter((player: Player) => player.team === team);
		});
}
