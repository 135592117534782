import { BagelQuestion } from '@api/models/bagel-question.entity';
import { BagelQuestionStatus } from '@api/models/enums';
import { createAction, props } from '@ngrx/store';

type UpdateAction = {
	bagelId: string;
	status: BagelQuestionStatus;
};

export type BagelQuestionUpdateSuccessAction = UpdateAction & { _id: string };

export type BagelQuestionUpdateAction = UpdateAction;

export type BagelQuestionFixAction = {
	bagelId: string;
	word: BagelQuestion;
};

export class BagelQuestionActions {
	public static update = createAction(
		'[BagelQuestion] Update',
		props<BagelQuestionUpdateAction>(),
	);

	public static updateSuccess = createAction(
		'[BagelQuestion] Update Success',
		props<BagelQuestionUpdateSuccessAction>(),
	);
}
