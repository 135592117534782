import produce, { Draft } from 'immer';

import { AlphabetSoupPhase } from '@api/models/alphabet-soup-phase.entity';
import { PhaseKind } from '@api/models/enums';
import { on } from '@ngrx/store';

import { GamesState } from '../../games.reducer';
import {
	AlphabetSoupActions,
	AlphabetSoupUpdateSuccessAction,
} from './alphabet-soup.actions';

export const alphabetSoupFeatureKey = 'alphabetSoup';

function updateSuccessFn(
	state: GamesState,
	action: AlphabetSoupUpdateSuccessAction,
): GamesState {
	const {
		roundId,
		soup: { _id: soupId, over, rebounds, turnOwner, covered },
	} = action;

	return produce(state, (newState: Draft<GamesState>) => {
		const soupPhase = newState.current.phases.find(
			({ kind }) => kind === PhaseKind.Soups,
		) as AlphabetSoupPhase;

		const round = soupPhase.rounds.find(({ _id }) => _id === roundId);

		const soup = round.soups.find(({ _id }) => _id === soupId);

		soup.rebounds = rebounds;
		soup.turnOwner = turnOwner;
		soup.covered = covered;
		soup.over = over;
	});
}

export const alphabetSoupReducers = [
	on(AlphabetSoupActions.updateSuccess, updateSuccessFn),
];
