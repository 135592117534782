import { AlphabetSoup } from '@api/models/alphabet-soup.entity';
import { AlphabetSoupAnswerStatus } from '@api/models/enums';
import { createAction, props } from '@ngrx/store';

export interface AlphabetSoupUpdateSuccessAction {
	roundId: string;
	soup: AlphabetSoup;
}

export interface AlphabetSoupReboundAction {
	roundId: string;
}

export interface AlphabetSoupFixAction {
	roundId: string;
	soupId: string;
	rebounds: number;
	answers: AlphabetSoupAnswerStatus[];
	turnOwner: string;
}

export class AlphabetSoupActions {
	public static updateSuccess = createAction(
		'[AlphabetSoup] Update Success',
		props<AlphabetSoupUpdateSuccessAction>(),
	);

	public static rebound = createAction(
		'[AlphabetSoup] Rebound',
		props<AlphabetSoupReboundAction>(),
	);

	public static fix = createAction(
		'[AlphabetSoup] Fix',
		props<AlphabetSoupFixAction>(),
	);
}
