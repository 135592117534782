import { AlphabetSoupAnswer } from '@api/models/alphabet-soup-answer.entity';
import { createAction, props } from '@ngrx/store';

export interface AlphabetSoupAnswerHitAction {
	roundId: string;
	answerId: string;
}

export interface AlphabetSoupAnswerUpdateSuccessAction {
	roundId: string;
	soupId: string;
	answer: AlphabetSoupAnswer;
}

export class AlphabetSoupAnswerActions {
	public static hit = createAction(
		'[AlphabetSoupAnswer] Hit',
		props<AlphabetSoupAnswerHitAction>(),
	);

	public static updateSuccess = createAction(
		'[AlphabetSoupAnswer] UpdateSuccess',
		props<AlphabetSoupAnswerUpdateSuccessAction>(),
	);
}
