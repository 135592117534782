import { SongRound } from '@api/models/song-round.entity';
import { createAction, props } from '@ngrx/store';

export interface SongRoundUpdateSuccessAction {
	round: Partial<SongRound>;
}

export class SongRoundActions {
	public static updateSuccess = createAction(
		'[SongRound] Update Success',
		props<SongRoundUpdateSuccessAction>(),
	);
}
