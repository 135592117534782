import { MemoryGridStatus, MemoryPhaseIndex } from '@api/models/enums';
import { MemoryGrid } from '@api/models/memory-grid.entity';
import { createAction, props } from '@ngrx/store';

export interface MemoryGridFixAction {
	gridId: string;
	wordsNumberHitted: MemoryPhaseIndex[];
}

export interface MemoryGridFixSuccessAction {
	grid: MemoryGrid;
}

export interface MemoryGridFlipAction {
	gridId: string;
	status: MemoryGridStatus;
}

export interface MemoryGridTogglePlayingAction {
	gridId: string;
	playing?: boolean;
}

export interface MemoryGridUpdateSuccessAction {
	grid: Partial<MemoryGrid>;
}

export class MemoryGridActions {
	public static fix = createAction(
		'[MemoryGrid] Fix',
		props<MemoryGridFixAction>(),
	);

	public static fixSuccess = createAction(
		'[MemoryGrid] Fix Success',
		props<MemoryGridFixSuccessAction>(),
	);

	public static flip = createAction(
		'[MemoryGrid] Flip',
		props<MemoryGridFlipAction>(),
	);

	public static togglePlaying = createAction(
		'[MemoryGrid] Toggle Playing',
		props<MemoryGridTogglePlayingAction>(),
	);

	public static updateSuccess = createAction(
		'[MemoryGrid] Update Success',
		props<MemoryGridUpdateSuccessAction>(),
	);
}
