import { Player } from '@api/models/player.entity';
import { createAction, props } from '@ngrx/store';

export type PlayersToggleReadyAction = {
	ready: boolean;
};

export type PlayersUpdateSuccessAction = {
	player: Player;
};

export class PlayersActions {
	public static toggleReady = createAction(
		'[Players] Toggle Ready',
		props<PlayersToggleReadyAction>(),
	);

	public static updateSuccess = createAction(
		'[Players] Update Success',
		props<PlayersUpdateSuccessAction>(),
	);
}
