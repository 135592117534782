import { SongClueAnswerType, SongClueStatus } from '@api/models/enums';
import { SongClue } from '@api/models/song-clue.entity';
import { createAction, props } from '@ngrx/store';

export interface SongClueUpdateStatusAction {
	songRoundId: string;
	status: SongClueStatus.Playing | SongClueStatus.ReadyForAnswer;
}

export interface SongClueUpdateSuccessAction {
	songRoundId: string;
	clue: Partial<SongClue>;
}

export class SongClueTryToAnswerAction {
	public reactionMiliseconds: number;
}

export interface SongClueValidateAnswerAction {
	songRoundId: string;
	answerType: SongClueAnswerType;
}

export class SongClueActions {
	public static updateStatus = createAction(
		'[SongClue] Update Status',
		props<SongClueUpdateStatusAction>(),
	);

	public static tryToAnswer = createAction(
		'[SongClue] Try To Answer',
		props<SongClueTryToAnswerAction>(),
	);

	public static validateAnswer = createAction(
		'[SongClue] Validate Answer',
		props<SongClueValidateAnswerAction>(),
	);

	public static updateSuccess = createAction(
		'[SongClue] Update Success',
		props<SongClueUpdateSuccessAction>(),
	);
}
