import { Draft, produce } from 'immer';

import { AlphabetSoupPhase } from '@api/models/alphabet-soup-phase.entity';
import { PhaseKind } from '@api/models/enums';
import { on } from '@ngrx/store';

import { GamesState } from '../../games.reducer';
import {
	AlphabetSoupAnswerActions,
	AlphabetSoupAnswerUpdateSuccessAction,
} from './alphabet-soup-answer.actions';

export const alphabetSoupAnswerFeatureKey = 'alphabetSoupAnswer';

function updateSuccessFn(
	state: GamesState,
	action: AlphabetSoupAnswerUpdateSuccessAction,
): GamesState {
	const {
		roundId,
		soupId,
		answer: { _id: answerId, status },
	} = action;

	return produce(state, (newState: Draft<GamesState>) => {
		const soupPhase = newState.current.phases.find(
			({ kind }) => kind === PhaseKind.Soups,
		) as AlphabetSoupPhase;

		const round = soupPhase.rounds.find(({ _id }) => _id === roundId);

		const soup = round.soups.find(({ _id }) => _id === soupId);

		const answer = soup.answers.find(({ _id }) => _id === answerId);

		answer.status = status;
	});
}

export const alphabetSoupAnswerReducers = [
	on(AlphabetSoupAnswerActions.updateSuccess, updateSuccessFn),
];
