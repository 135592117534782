import produce, { Draft } from 'immer';

import { AlphabetSoupPhase } from '@api/models/alphabet-soup-phase.entity';
import { PhaseKind } from '@api/models/enums';
import { on } from '@ngrx/store';

import { GamesState } from '../games.reducer';
import { AlphabetSoupPhaseActions } from './alphabet-soup-phase.actions';

export const alphabetSoupPhaseFeatureKey = 'alphabetSoupPhase';

function finishFn(state: GamesState): GamesState {
	return produce(state, (newState: Draft<GamesState>) => {
		const souPhase = newState.current.phases.find(
			({ kind }) => kind === PhaseKind.Soups,
		) as AlphabetSoupPhase;

		souPhase.over = true;
	});
}

export const alphabetSoupPhaseReducers = [
	on(AlphabetSoupPhaseActions.finish, finishFn),
];
