import { Game } from '@api/models/game.entity';
import { Phase } from '@bussiness/custom-models/game.custom-models';
import { Action, createReducer, on } from '@ngrx/store';

import { alphabetSoupAnswerReducers } from './alphabet-soup-phase/alphabet-soup-answer/alphabet-soup-answer.reducer';
import { alphabetSoupPhaseReducers } from './alphabet-soup-phase/alphabet-soup-phase.reducer';
import { alphabetSoupRoundReducers } from './alphabet-soup-phase/alphabet-soup-round/alphabet-soup-round.reducer';
import { alphabetSoupReducers } from './alphabet-soup-phase/alphabet-soup/alphabet-soup.reducer';
import { bagelQuestionReducers } from './bagels-phase/bagel-question/bagel-question.reducer';
import { bagelPhaseReducers } from './bagels-phase/bagels-phase.reducer';
import {
	GameAddPhaseSuccessAction,
	GamesActions,
	GamesLoadSuccessAction,
	GamesSetAction,
	GameUpdateAction,
} from './games.actions';
import { memoryCellReducers } from './memory-phase/memory-cell/memory-cell.reducer';
import { memoryGridReducers } from './memory-phase/memory-grid/memory-grid.reducer';
import { memoryPhaseReducers } from './memory-phase/memory-phase.reducer';
import { playersReducers } from './players/players.reducer';
import { songClueReducers } from './song-phase/song-clue/song-clue.reducer';
import { songPhaseReducers } from './song-phase/song-phase.reducer';
import { songRoundReducers } from './song-phase/song-round/song-round.reducer';

export const gamesFeatureKey = 'games';

export interface GamesState {
	current: Game;
	list: Game[];
}

export const initialState: GamesState = {
	current: null,
	list: [],
};

function setCurrentFunc(state: GamesState, action: GamesSetAction): GamesState {
	const { game } = action;

	return {
		...state,
		current: game,
	};
}

function loadSuccessFunc(
	state: GamesState,
	action: GamesLoadSuccessAction,
): GamesState {
	const { games } = action;

	return {
		...state,
		list: games,
	};
}

function loadNextSuccessFunc(
	state: GamesState,
	action: GamesLoadSuccessAction,
): GamesState {
	return {
		...state,
		list: state.list.concat(action.games),
	};
}

function updateFunc(state: GamesState, action: GameUpdateAction): GamesState {
	return {
		...state,
		current: {
			...state.current,
			viewers: action.viewers ?? state.current.viewers,
			over: action.over ?? state.current.over,
		},
	};
}

function addPhaseFunc(
	state: GamesState,
	action: GameAddPhaseSuccessAction,
): GamesState {
	const { phase: newPhase } = action;

	return {
		...state,
		current: {
			...state.current,
			phases: state.current.phases.map((phase: Phase) => {
				if (phase.kind === newPhase.kind) return newPhase;

				return phase;
			}),
		},
	};
}

export const reducer = createReducer(
	initialState,
	on(GamesActions.createSuccess, setCurrentFunc),
	on(GamesActions.getCurrentSuccess, setCurrentFunc),
	on(GamesActions.loadSuccess, loadSuccessFunc),
	on(GamesActions.loadNextSuccess, loadNextSuccessFunc),
	on(GamesActions.updateSuccess, updateFunc),
	on(GamesActions.addPhaseSuccess, addPhaseFunc),
	...playersReducers,
	...memoryPhaseReducers,
	...memoryGridReducers,
	...memoryCellReducers,
	...bagelPhaseReducers,
	...bagelQuestionReducers,
	...songPhaseReducers,
	...songRoundReducers,
	...songClueReducers,
	...alphabetSoupPhaseReducers,
	...alphabetSoupRoundReducers,
	...alphabetSoupReducers,
	...alphabetSoupAnswerReducers,
);

export function gamesReducer(state = initialState, action: Action): GamesState {
	return reducer(state, action);
}
