import { Player } from '@api/models/player.entity';
import { on } from '@ngrx/store';

import { GamesState } from '../games.reducer';
import { PlayersActions, PlayersUpdateSuccessAction } from './players.actions';

export const playersFeatureKey = 'players';

const updateFunc = (
	state: GamesState,
	{ player: newPlayer }: PlayersUpdateSuccessAction,
): GamesState => {
	return {
		...state,
		current: {
			...state.current,
			players: state.current.players.map((player: Player) =>
				player._id === newPlayer._id ? newPlayer : player,
			),
		},
	};
};

export const playersReducers = [on(PlayersActions.updateSuccess, updateFunc)];
