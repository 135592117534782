import { AlphabetSoupRound } from '@api/models/alphabet-soup-round.entity';
import { createAction, props } from '@ngrx/store';

export interface AlphabetSoupRoundTooglePlayingAction {
	roundId: string;
	play: boolean;
	timeOut?: boolean;
}

export interface AlphabetSoupRoundUpdateSuccessAction {
	round: AlphabetSoupRound;
}

export interface AlphabetSoupRoundChangeTimeSuccessAction {
	roundId: string;
	time: number;
}

export class AlphabetSoupRoundActions {
	public static togglePlaying = createAction(
		'[AlphabetSoupRound] Toogle Playing',
		props<AlphabetSoupRoundTooglePlayingAction>(),
	);

	public static updateSuccess = createAction(
		'[AlphabetSoupRound] Update Success',
		props<AlphabetSoupRoundUpdateSuccessAction>(),
	);

	public static changeTimeSuccess = createAction(
		'[AlphabetSoupRound] Change Time Success',
		props<AlphabetSoupRoundChangeTimeSuccessAction>(),
	);
}
