import { BagelQuestion } from '@api/models/bagel-question.entity';
import { createAction, props } from '@ngrx/store';

export interface BagelPhaseChangeTimeAction {
	id: string;
	time: number;
}

export interface BagelPhaseTogglePlayingAction {
	id: string;
	playing: boolean;
}

export interface BagelPhaseUpdateSuccessAction {
	id: string;
	inGameQuestion: string;
	hasTurn?: boolean;
	time?: number;
	playing?: boolean;
	over?: boolean;
}

export interface BagelPhaseFixAction {
	bagelId: string;
	bagelQuestion: BagelQuestion;
	changeTurn: boolean;
}

export class BagelPhaseActions {
	public static changeTime = createAction(
		'[BagelsPhase] Change Time',
		props<BagelPhaseChangeTimeAction>(),
	);

	public static togglePlaying = createAction(
		'[BagelsPhase] Toggle Playing',
		props<BagelPhaseTogglePlayingAction>(),
	);

	public static fix = createAction(
		'[BagelsPhase] Fix',
		props<BagelPhaseFixAction>(),
	);

	public static updateSuccess = createAction(
		'[BagelsPhase] Update Success',
		props<BagelPhaseUpdateSuccessAction>(),
	);
}
