import produce, { Draft } from 'immer';

import { BagelPhase } from '@api/models/bagel-phase.entity';
import { PhaseKind } from '@api/models/enums';
import { on } from '@ngrx/store';

import { GamesState } from '../../games.reducer';
import {
	BagelQuestionActions,
	BagelQuestionUpdateSuccessAction,
} from './bagel-question.actions';

export const bagelQuestionFeatureKey = 'bagelQuestion';

function updateFn(
	state: GamesState,
	action: BagelQuestionUpdateSuccessAction,
): GamesState {
	const { bagelId, _id: bagelQuestionId, status } = action;

	return produce(state, (newState: Draft<GamesState>) => {
		const bagelPhase = newState.current.phases.find(
			({ kind }) => kind === PhaseKind.Bagels,
		) as BagelPhase;

		const bagel = bagelPhase.bagels.find(({ _id }) => _id === bagelId);

		const bagelQuestion = bagel.questions.find(
			({ _id }) => _id === bagelQuestionId,
		);
		bagelQuestion.status = status;
	});
}

export const bagelQuestionReducers = [
	on(BagelQuestionActions.updateSuccess, updateFn),
];
