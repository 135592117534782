export class StringUtils {
	/**
	 * Replaces placeholders in a given path string with corresponding values.
	 *
	 * @template T - The type representing the parameters to be replaced in the path.
	 * @param {string} path - The path string containing placeholders in the format `${key}`.
	 * @param {T} params - An object containing key-value pairs to replace the placeholders in the path.
	 * @returns {string} - The path string with replaced values.
	 *
	 * @example
	 * const path = "/users/${userId}/profile";
	 * const params = { userId: "123" };
	 * const result = StringUtils.populatePath(path, params);
	 * // Result: "/users/123/profile"
	 */
	public static populatePath<T extends Record<string, any>>(
		path: string,
		params: T,
	): string {
		for (const [key, value] of Object.entries(params))
			path = path.replace(new RegExp(`\\$\\{${key}\\}`, 'g'), value);

		return path;
	}
}
