import produce, { Draft } from 'immer';

import { PhaseKind } from '@api/models/enums';
import { SongPhase } from '@api/models/song-phase.entity';
import { on } from '@ngrx/store';

import { GamesState } from '../../games.reducer';
import {
	SongClueActions,
	SongClueUpdateSuccessAction,
} from './song-clue.actions';

export const songClueFeatureKey = 'songClue';

function updateSuccessFunc(
	state: GamesState,
	action: SongClueUpdateSuccessAction,
): GamesState {
	const {
		songRoundId,
		clue: { _id: songClueId, turnOwner, status },
	} = action;

	return produce(state, (newState: Draft<GamesState>) => {
		const songPhase = newState.current.phases.find(
			({ kind }) => kind === PhaseKind.Song,
		) as SongPhase;

		const round = songPhase.rounds.find(({ _id }) => _id === songRoundId);

		const clue = round.clues.find(({ _id }) => _id === songClueId);
		clue.turnOwner = turnOwner ?? clue.turnOwner;
		clue.status = status ?? clue.status;
	});
}

export const songClueReducers = [
	on(SongClueActions.updateSuccess, updateSuccessFunc),
];
