import produce, { Draft } from 'immer';

import { AlphabetSoupPhase } from '@api/models/alphabet-soup-phase.entity';
import { PhaseKind } from '@api/models/enums';
import { on } from '@ngrx/store';

import { GamesState } from '../../games.reducer';
import {
	AlphabetSoupRoundActions,
	AlphabetSoupRoundChangeTimeSuccessAction,
	AlphabetSoupRoundUpdateSuccessAction,
} from './alphabet-soup-round.actions';

export const alphabetSoupRoundFeatureKey = 'alphabetSoupRound';

function updateSuccessFn(
	state: GamesState,
	action: AlphabetSoupRoundUpdateSuccessAction,
): GamesState {
	const {
		round: { _id: roundId, over, playing, time, score },
	} = action;

	return produce(state, (newState: Draft<GamesState>) => {
		const soupPhase = newState.current.phases.find(
			({ kind }) => kind === PhaseKind.Soups,
		) as AlphabetSoupPhase;

		const round = soupPhase.rounds.find(({ _id }) => _id === roundId);

		round.playing = playing;
		round.time = time;
		round.score = score;
		round.over = over;
	});
}

function changeTimeSuccessFn(
	state: GamesState,
	action: AlphabetSoupRoundChangeTimeSuccessAction,
): GamesState {
	const { roundId, time } = action;

	return produce(state, (newState: Draft<GamesState>) => {
		const soupPhase = newState.current.phases.find(
			({ kind }) => kind === PhaseKind.Soups,
		) as AlphabetSoupPhase;

		const round = soupPhase.rounds.find(({ _id }) => _id === roundId);

		round.time = time;
	});
}

export const alphabetSoupRoundReducers = [
	on(AlphabetSoupRoundActions.updateSuccess, updateSuccessFn),
	on(AlphabetSoupRoundActions.changeTimeSuccess, changeTimeSuccessFn),
];
