import produce, { Draft } from 'immer';

import { PhaseKind } from '@api/models/enums';
import { MemoryPhase } from '@api/models/memory-phase.entity';
import { on } from '@ngrx/store';

import { GamesState } from '../../games.reducer';
import {
	MemoryGridActions,
	MemoryGridUpdateSuccessAction,
} from './memory-grid.actions';

export const memoryGridFeatureKey = 'memoryGrid';

function fixSuccessFunc(
	state: GamesState,
	action: MemoryGridUpdateSuccessAction,
): GamesState {
	const {
		grid: { _id: gridId, cells },
	} = action;

	return produce(state, (newState: Draft<GamesState>) => {
		const memoryPhase = newState.current.phases.find(
			({ kind }) => kind === PhaseKind.Memory,
		) as MemoryPhase;

		const grid = memoryPhase.grids.find(({ _id }) => _id === gridId);

		grid.cells = cells;
	});
}

function updateSuccessFunc(
	state: GamesState,
	action: MemoryGridUpdateSuccessAction,
): GamesState {
	const {
		grid: { _id: gridId, time, playing, score, status, turnOwner, over },
	} = action;

	return produce(state, (newState: Draft<GamesState>) => {
		const memoryPhase = newState.current.phases.find(
			({ kind }) => kind === PhaseKind.Memory,
		) as MemoryPhase;

		const grid = memoryPhase.grids.find(({ _id }) => _id === gridId);

		grid.time = time ?? grid.time;
		grid.playing = playing ?? grid.playing;
		grid.score = score ?? grid.score;
		grid.status = status ?? grid.status;
		grid.turnOwner = turnOwner ?? grid.turnOwner;
		grid.over = over ?? grid.over;
	});
}

export const memoryGridReducers = [
	on(MemoryGridActions.fixSuccess, fixSuccessFunc),
	on(MemoryGridActions.updateSuccess, updateSuccessFunc),
];
