import produce, { Draft } from 'immer';

import { PhaseKind } from '@api/models/enums';
import { SongPhase } from '@api/models/song-phase.entity';
import { on } from '@ngrx/store';

import { GamesState } from '../../games.reducer';
import {
	SongRoundActions,
	SongRoundUpdateSuccessAction,
} from './song-round.actions';

export const songRoundFeatureKey = 'songRound';

function updateSuccessFn(
	state: GamesState,
	action: SongRoundUpdateSuccessAction,
): GamesState {
	const {
		round: { _id: songRoundId, hittedBy },
	} = action;

	return produce(state, (newState: Draft<GamesState>) => {
		const songPhase = newState.current.phases.find(
			({ kind }) => kind === PhaseKind.Song,
		) as SongPhase;

		const round = songPhase.rounds.find(({ _id }) => _id === songRoundId);

		round.hittedBy = hittedBy ?? round.hittedBy;
	});
}

export const songRoundReducers = [
	on(SongRoundActions.updateSuccess, updateSuccessFn),
];
