import produce, { Draft } from 'immer';

import { BagelPhase } from '@api/models/bagel-phase.entity';
import { PhaseKind } from '@api/models/enums';
import { on } from '@ngrx/store';

import { GamesState } from '../games.reducer';
import {
	BagelPhaseActions,
	BagelPhaseChangeTimeAction,
	BagelPhaseTogglePlayingAction,
	BagelPhaseUpdateSuccessAction,
} from './bagels-phase.actions';

export const bagelsPhaseFeatureKey = 'bagelsPhase';

type UpdateAction =
	| BagelPhaseUpdateSuccessAction
	| BagelPhaseTogglePlayingAction
	| BagelPhaseChangeTimeAction;

type UpdateActionPartial = Partial<
	BagelPhaseUpdateSuccessAction &
		BagelPhaseTogglePlayingAction &
		BagelPhaseChangeTimeAction
>;

function updateFn(state: GamesState, action: UpdateAction): GamesState {
	const {
		id: bagelId,
		inGameQuestion,
		time,
		playing,
		hasTurn,
		over,
	} = action as UpdateActionPartial;

	return produce(state, (newState: Draft<GamesState>) => {
		const bagelPhase = newState.current.phases.find(
			({ kind }) => kind === PhaseKind.Bagels,
		) as BagelPhase;

		const bagel = bagelPhase.bagels.find(({ _id }) => _id === bagelId);

		bagel.inGameQuestion = inGameQuestion ?? bagel.inGameQuestion;
		bagel.hasTurn = hasTurn ?? bagel.hasTurn;
		bagel.time = time ?? bagel.time;
		bagel.playing = over ? false : playing ?? bagel.playing;
		bagel.over = over ?? bagel.over;
	});
}

export const bagelPhaseReducers = [
	on(BagelPhaseActions.changeTime, updateFn),
	on(BagelPhaseActions.togglePlaying, updateFn),
	on(BagelPhaseActions.updateSuccess, updateFn),
];
